<template>
  <div class="block-space bg-gradient-teal" data-app>
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-lg-12 order-lg-1 p-0">
          <div class="px-3">
            <v-row no-gutters class="mx-auto w-100 flex-nowrap align-items-center">
              <v-icon
                x-large
                class="icon icon-lg bg-gradient-white shadow rounded-circle text-primary"
                >mdi-calendar</v-icon
              >
              <h1 class="h3 mx-2 mb-0 text-white">{{ title }}</h1>
              <v-btn
                color="#fff"
                dark
                class="mr-2"
                outlined
                text
                @click.prevent="
                  future = true
                  getSchedule()
                "
                >{{ button1 }}</v-btn
              >
              <v-btn
                color="#fff"
                dark
                outlined
                text
                @click.prevent="
                  future = false
                  getSchedule()
                "
                >{{ button2 }}</v-btn
              >
            </v-row>
            <!-- <div class="pl-4">
              <p class="text-white" v-if="desc && desc != ''">{{ desc }}</p>
            </div> -->
          </div>
          <swiper class="swiper p-5" :options="swiperOption">
            <swiper-slide
              class="card p-3 shadow shadow-lg--hover pointer"
              v-for="(item, id) in schedule"
              :key="id"
            >
              <div @click="$emit('updateSelectedPublication', item)">
                <h5 class="h6 text-default mb-0" style="height: 70px">
                  {{ item.title }}
                </h5>
                <v-card-text style="" class="my-3 p-0">
                  <span v-if="item.modality"
                    ><strong>Modalidad:</strong> {{ item.modalityText }}</span
                  ><br />
                  <span v-if="item.dateRange > 0 && item.startAt"
                    ><strong>Inicia:</strong> {{ item.startAt }}</span
                  ><br v-if="item.dateRange > 0 && item.startAt" />
                  <span v-if="item.dateRange > 0 && item.expired_at"
                    ><strong>Culmina:</strong> {{ item.endAt }}</span
                  ><br v-if="item.dateRange > 0 && item.expired_at" />
                  <span v-if="item.dateRange == 0"
                    ><strong>Fecha:</strong> {{ item.startAt }}</span
                  ><br v-if="item.dateRange == 0" />
                </v-card-text>
                <countries-flags :countries="item.countries" class="mb-3" />
                <!-- <p v-html="`${extractContent(item.shortdesc)}`"></p> -->
                <button-show-more
                  style="position: relative; right: 0; left: calc(100% - 24px)"
                  @click="$emit('updateSelectedPublication', item)"
                />
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ButtonShowMore from './ButtonShowMore.vue'
  import CountriesFlags from './CountriesFlags.vue'
  export default {
    name: 'Schedule2',
    title: 'Navigation',
    props: {
      title: { type: String },
      button1: { type: String },
      button2: { type: String },
    },
    components: { ButtonShowMore, CountriesFlags },
    data() {
      return {
        schedule: [],
        future: true,
        loadingShedule: null,
        swiperOption: {
          centerInsufficientSlides: true,
          speed: 300,
          autoplay: {
            delay: 300000,
            // waitForTransition:false,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            type: 'bullets',
          },
          setWrapperSize: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            1440: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            // 1025: {
            //   slidesPerView: 3,
            //   spaceBetween: 30,
            // },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },

            320: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          },
        },
      }
    },
    created() {
      this.getSchedule()
    },
    methods: {
      extractContent(html) {
        return new DOMParser().parseFromString(html, 'text/html').documentElement
          .textContent
      },
      async getSchedule() {
        this.loadingShedule = true
        let orderByString = ''
        let futureSwitch = ''
        this.future ? (futureSwitch = '&future=1') : (futureSwitch = '&last=1')
        this.future ? (orderByString = '&by=asc') : (orderByString = '&by=desc')

        axios
          .get(
            'publications?order=date&type=schedule&limit=10&active=1' +
              orderByString +
              futureSwitch
          )
          .then((response) => {
            this.schedule = response.data
            this.schedule.map((item) => {
              item.startAt = new Date(item.date).toLocaleDateString('es-VE', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
              item.endAt = new Date(item.expired_at).toLocaleDateString('es-VE', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
              item.dateRange = Math.ceil(
                Math.abs(new Date(item.date) - new Date(item.expired_at)) /
                  (1000 * 60 * 60 * 24)
              )
              switch (item.modality) {
                case 'site':
                  item.modalityText = 'Presencial'
                  break
                case 'remote':
                  item.modalityText = 'Virtual'
                  break
                case 'remote-site':
                  item.modalityText = 'Virtual - Presencial'
                  break
                default:
                  item.modalityText = 'N/A'
                  break
              }
            })
            this.loadingShedule = false
          })
      },
    },
  }
</script>

<style lang="scss">
  // @import './base.scss';
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.8;
  }
  .swiper-pagination-hidden {
    display: none;
  }
  .v-btn__content {
    text-transform: initial;
  }
</style>
