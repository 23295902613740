<template>
  <div class="block-space bg-secondary">
    <h1 class="h3 m-0 w-100 text-default text-center">{{ title }}</h1>
    <p class="text-center"></p>
    <!-- Listing Item -->
    <v-row no-gutters class="container mx-auto p-0 p-sm-auto">
      <div class="col-lg-6 col-12 px-0 px-sm-2" v-for="item of news" :key="item.id">
        <v-row
          @click="$emit('updateSelectedPublication', item)"
          no-gutters
          class="m-2 bg-white justify-content-center shadow pointer"
        >
          <picture class="col-5 col-sm-4 p-0 d-flex">
            <v-img
              :aspect-ratio="1 / 1.5"
              contain
              v-if="item.cover && item.cover.route != '' && $vuetify.breakpoint.lgAndUp"
              :src="item.cover.route"
              class="img-fluid"
            />
            <v-img
              :aspect-ratio="1 / 1.2"
              contain
              v-else-if="
                item.cover && item.cover.route != '' && $vuetify.breakpoint.lgAndDown
              "
              :src="item.cover.route"
              class="img-fluid"
            />
          </picture>
          <div class="col-7 col-sm-8 d-flex flex-column p-3">
            <h5 style="min-height: 90px">{{ item.title }}</h5>
            <p style="min-height: 108px" v-html="item.shortdesc"></p>
            <v-row no-gutters class="flex-column justify-content-end align-items-end">
              <button-show-more
                class="justify-self-end"
                @click="$emit('updateSelectedPublication', item)"
              />
            </v-row>
          </div>
        </v-row>

        <!-- Listing Item / End -->
      </div>
    </v-row>
    <!-- Pagination -->
    <v-pagination
      circle
      class="mx-auto mt-5 w-100"
      color="#0c2c7c"
      v-model="page"
      :length="pages"
    />
    <!-- Pagination / End -->
  </div>
</template>

<script>
  import ButtonShowMore from './ButtonShowMore.vue'
  export default {
    name: 'News',
    data() {
      return {
        news: [],
        loading: false,
        page: 1,
        pages: 1,
      }
    },
    components: { ButtonShowMore },
    props: {
      title: String,
    },
    watch: {
      page() {
        this.getNews()
      },
    },
    created() {
      this.getNews()
    },
    methods: {
      extractContent(html) {
        return new DOMParser().parseFromString(html, 'text/html').documentElement
          .textContent
      },
      getNews() {
        this.loading = true
        axios
          .get(
            'publications?order=date&by=desc&type=news&active=1&public=1&page=' +
              this.page
          )
          .then((response) => {
            this.news = response.data.data
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.loading = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
